/* App.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4; /* Light background color */
}

nav {
    padding: 10px 0;
    border-radius: 5px;
    width: 100%;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

li {
    display: inline-block;
    margin: 0 15px;
    cursor: pointer;
    color: white; /* Text color */
    transition: font-weight 0.3s ease;
    font-size: 18px
}

li:hover {
    text-decoration: underline; /* Underline effect on hover */
}

.bold {
    font-weight: bold; /* Bold text for active component */
}

.component-container {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    width: 80%; /* Width of the component container */
    max-width: 800px; /* Maximum width */
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(135deg, #3b82f6, #06b6d4);
    color: #333;
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
}

.login-card {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
}

.login-card h1 {
    margin-bottom: 10px;
    font-size: 24px;
}

.login-card p {
    margin-bottom: 20px;
    color: #555;
}

.input-group {
    margin-bottom: 15px;
}

input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
}

input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
}

.cta-button {
    width: 100%;
    padding: 12px;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #2563eb;
}

.link-button {
    background: none;
    border: none;
    color: #3b82f6;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.separator {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.separator span {
    background: white;
    padding: 0 10px;
    font-size: 14px;
    color: #555;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    height: 1px;
    background: #ccc;
}

.google-login-button {
    width: 100%;
    padding: 12px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.google-login-button:hover {
    background-color: #f0f0f0;
    border-color: #bbb;
}

.google-login-button img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.profile-menu {
    position: absolute;
    top: 20px;
    right: 20px;
}

.profile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
}

.profile-button:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
}

.profile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}

.profile-initials {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
}

.user-details {
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    width: auto;
    max-width: 240px;
    padding: 10px;
    background-color: rgba(211, 211, 211, 0.9);
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    color: #333;
    text-align: left;
    white-space: normal;
    word-wrap: break-word;
    z-index: 999;
    margin-left: -120px;
}

.profile-button:hover ~ .user-details {
    display: block;
}

.logout-icon {
    display: none;
    width: 16px;
    height: 16px;
}

.profile-button:hover .logout-icon {
    display: block;
}


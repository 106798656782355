.breakeven-analysis {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    width: 100%;
    box-sizing: border-box;
}

.breakeven-analysis h3 {
    font-size: 1.25em;
    margin-bottom: 20px;
    text-align: center;
}

.breakeven-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    table-layout: fixed; /* Ensure even column widths */
}

.breakeven-table td {
    width: 50%; /* Equal column width */
    padding: 10px;
    text-align: center; /* Center content */
    vertical-align: middle; /* Center vertically */
}

.label-cell {
    font-weight: bold;
    color: #333;
}

.input-cell select {
    width: 100%; /* Full width of cell */
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center; /* Center dropdown text */
}

.breakeven-calculate-button {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 1em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.breakeven-calculate-button:hover {
    background-color: #0056b3;
}

.breakeven-result {
    margin-top: 20px;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    color: #333;
}

.breakeven-instructions {
    font-size: 0.7em;
    color: #555;
    margin-top: 20px;
    text-align: justify;
}

.centered-bold {
    margin-top: 15px;
    display: block;
    text-align: center;
    font-weight: bold;
}

/* Main container for the cap table */
.cap-table-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  position: relative;
  overflow-x: auto; /* Ensures table doesn't overflow */
}

/* Wrapper to allow scrolling on small screens */
.cap-table-wrapper {
  width: 100%;
  overflow-x: auto;
}

/* Styling for the table */
.cap-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  min-width: 600px; /* Prevents columns from getting too small */
}

.cap-table h3 {
  font-size: 1.25em;
  margin-bottom: 20px;
  text-align: center;
}

.cap-table th,
.cap-table td {
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  color: #000;
  white-space: nowrap; /* Prevents text from wrapping */
  word-break: keep-all; /* Ensures text doesn't break awkwardly */
}

.cap-table th {
  background-color: #f0f0f0; /* Neutral background for headers */
  font-weight: bold;
  color: #000; /* Dark text color for contrast */
}

.cap-table tr:nth-child(even) {
  background-color: #fafafa; /* Lighter background for alternate rows */
}

.cap-table tr:hover {
  background-color: #f1f1f1; /* Highlight rows on hover */
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .cap-table-container {
    padding: 10px;
  }

  .cap-table-wrapper {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .cap-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .cap-table th,
  .cap-table td {
    padding: 10px;
    font-size: 14px;
  }
}

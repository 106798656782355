/* General container styling */
.calculator-container {
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 40px auto;
    font-family: 'Arial', sans-serif;
    border: 1px solid #e0e0e0;
}

/* Heading styling */
h2 {
    text-align: center;
    color: #0056b3;
    margin-bottom: 20px;
    font-size: 24px;
    letter-spacing: 1px;
}

h4 {
  text-align: center;
  color: #007bff; /* Slightly lighter than h2 for visual hierarchy */
  font-size: 1rem;
  font-weight: bold;
  width: fit-content;
  margin-inline: auto; /* Centers the underline without additional margins */
}

/* Tooltip container layout */
.tooltip-container {
    position: relative; /* Make the container relative to position the tooltip */
    margin-bottom: 15px;
}

/* Label styling */
label {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    cursor: default; /* Set cursor to default to indicate it's not clickable */
}

/* Input and select fields styling */
input, select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
}

/* Tooltip text */
.tooltip-text {
    visibility: hidden; /* Hide tooltip by default */
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 100%; /* Tooltip appears just below the label */
    left: 0; /* Align tooltip with the label */
    opacity: 0; /* Hidden by default */
    transition: opacity 0.3s ease-in-out;
    font-size: 12px;
    margin-top: 5px; /* Space between label and tooltip */
    width: max-content;
    max-width: 200px;
}

/* Show tooltip on hover of the label */
.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

/* Button styling */
button {
    background-color: #007bff;
    color: white;
    padding: 12px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 25px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

/* Button hover state */
button:hover {
    background-color: #0056b3;
}

/* Result styling */
#result {
    margin-top: 30px;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    transition: color 0.3s;
}

#result.success {
    color: #28a745;
}

#result.error {
    color: #d8545f;
}

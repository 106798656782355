.calculator-container {
  max-width: 800px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Add some padding */
  border: 1px solid #ccc; /* Optional: add a border */
  border-radius: 5px; /* Optional: rounded corners */
  background-color: #f9f9f9; /* Light background for better contrast */
}

h2 {
  text-align: center; /* Center the headings */
  color: #333; /* Darker color for headings */
}

fieldset {
    border: 1px solid #007bff; /* Matching border color */
    border-radius: 8px; /* Rounded corners */
    margin-bottom: 20px; /* Space between sections */
    padding: 15px; /* Padding inside fieldset */
}

legend {
    font-size: 24px; /* Slightly larger text for legends */
    font-weight: bold; /* Bold legends */
    color: #007bff; /* Matching color */
    padding: 10px 10px 0 10px; /* Padding around the text */
    text-align: center;
}

h3 {
  text-align: center;
  color: #444; /* Slightly lighter than h2 for visual hierarchy */
  font-size: 1.5rem;
  font-weight: bold;
  width: fit-content;
  margin-inline: auto; /* Centers the underline without additional margins */
}

h4 {
  text-align: center;
  color: #007bff; /* Slightly lighter than h2 for visual hierarchy */
  font-size: 1rem;
  font-weight: bold;
  width: fit-content;
  margin-inline: auto; /* Centers the underline without additional margins */
}

label {
  display: block; /* Stack labels and inputs */
  margin: 10px 0; /* Add space between inputs */
}

input {
  width: 100%; /* Full width inputs */
  padding: 8px; /* Add padding inside inputs */
  margin-top: 5px; /* Space above inputs */
  border: 1px solid #ccc; /* Input border */
  border-radius: 4px; /* Rounded corners for inputs */
}

button {
  margin: 10px 5px; /* Space between buttons */
  padding: 10px 15px; /* Button padding */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  background-color: #007bff; /* Primary button color */
  color: white; /* Text color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Transition for hover effect */
}

button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.table-scroll {
  max-height: 400px; /* Set a maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: add a border for better visibility */
  margin-top: 20px; /* Space above the table */
}

table {
  width: 100%; /* Full width table */
  border-collapse: collapse; /* Remove spacing between cells */
}

th, td {
  padding: 10px; /* Padding inside cells */
  text-align: center; /* Center text in cells */
  border: 1px solid #ddd; /* Light border for table cells */
}

th {
  background-color: #f2f2f2; /* Light gray background for header */
  color: #333; /* Darker text for headers */
}

tbody tr:hover {
  background-color: #f5f5f5; /* Highlight row on hover */
}

@media (max-width: 600px) {
  /* Responsive styles for smaller screens */
  button {
    width: 100%; /* Full-width buttons */
    margin: 5px 0; /* Stack buttons */
  }
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin-bottom: 10px; /* Add some spacing below */
}

.button-container button {
  padding: 8px 12px;
  margin-left: 5px; /* Add space between buttons */
  font-size: 16px; /* Increase font size */
  cursor: pointer; /* Change cursor to pointer */
}

.button-container button:disabled {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Darker gray text */
  cursor: not-allowed; /* Change cursor to not-allowed */
  opacity: 0.6; /* Make the button less opaque */
}
.table-row td {
  width: 20%; /* Set a fixed or percentage-based width */
  text-align: center; /* Optional: center-align text */
  vertical-align: middle; /* Optional: vertically center content */
}

table {
  width: 100%; /* Table takes up full width */
  table-layout: fixed; /* Ensures equal width for all columns */
  border-collapse: collapse; /* Optional: removes gaps between cells */
}

input, select {
  box-sizing: border-box; /* Ensure consistent sizing */
}

.toggle-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.toggle-switch input[type="radio"] {
    display: none;
}

.toggle-switch label {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 20px;
    transition: background-color 0.3s, color 0.3s;
}

.toggle-switch input[type="radio"]:checked + label {
    background-color: #4a90e2;
    color: white;
    font-weight: bold;
}

.ellipses-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 5px;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: #007bff;
    border-radius: 50%;
    animation: blink 1.4s infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0%, 80%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

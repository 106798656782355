.exit-breakdown-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  position: relative;
  overflow-x: auto;
}

.exit-breakdown-wrapper {
  width: 100%;
  overflow-x: auto;
}

.exit-breakdown-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  min-width: 600px;
  color: #000;
}

.exit-breakdown h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.exit-breakdown th,
.exit-breakdown td {
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  color: #000;
}

.exit-breakdown th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.exit-breakdown tr:nth-child(even) {
  background-color: #fafafa;
}

.exit-breakdown tr:hover {
  background-color: #f1f1f1;
}

.founder-row {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  color: #000;
}

@media (max-width: 600px) {
  .exit-breakdown-container {
    padding: 10px;
  }

  .exit-breakdown th,
  .exit-breakdown td {
    padding: 10px;
    font-size: 14px;
  }
}

.investment-details {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    position: relative;
}

/* Table scroll container */
.table-scroll {
    overflow-x: auto;
    margin-bottom: 10px;
}

/* Ensure the founder value is displayed properly */
.founder-value {
    font-weight: bold;
    color: #333; /* Make sure the text is visible (adjust as per your theme) */
    margin-top: 20px; /* Add spacing between the table and the founder info */
    font-size: 18px;
}

.exit-input {
    width: 100%; /* Makes the input take up the full width */
    padding: 12px; /* Add padding for better text spacing */
    font-size: 16px; /* Slightly larger text for readability */
    border: 2px solid #ccc; /* Light border for a clean look */
    border-radius: 8px; /* Rounded corners for a modern look */
    background-color: #fff; /* White background for clarity */
    color: #333; /* Dark text for good readability */
    transition: border 0.3s ease; /* Smooth transition for focus effect */
}

/* Focus styling */
.exit-input:focus {
    outline: none; /* Remove default focus outline */
    border-color: #007bff; /* Blue border on focus for emphasis */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Soft shadow around the input */
}

/* Placeholder text styling */
.exit-input::placeholder {
    color: #888; /* Lighter gray for the placeholder */
    font-style: italic; /* Italicize the placeholder for a softer effect */
}

.investment-table td {
    text-align: center; /* Horizontally center content */
    vertical-align: middle; /* Vertically center content */
    font-size: 18px;
    color: #000;
}

/* Fix button styling within table */
.investment-table button {
    font-size: 14px; /* Ensure readable text */
    border: 1px solid #007bff; /* Match theme color */
    border-radius: 5px; /* Rounded edges */
    background-color: #007bff; /* Primary color */
    color: #fff; /* White text */
    cursor: pointer;
    width: 100%; /* Ensure button doesn't overflow */
    text-align: center; /* Center-align text */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically center text */
    justify-content: center; /* Horizontally center text */
    overflow: hidden; /* Prevent text overflow */
    white-space: nowrap; /* Prevent text wrapping */
    margin: auto;
}

.investment-table button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Mobile adjustments */
@media (max-width: 600px) {
    .investment-table {
        min-width: 600px;
    }

    .exit-input {
        font-size: 14px; /* Slightly smaller text for smaller screens */
        padding: 10px; /* Reduce padding on mobile */
    }
}

/* General container styling */
.issue-report-container {
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 40px auto;
    font-family: 'Arial', sans-serif;
    border: 1px solid #e0e0e0;
}

/* Heading styling */
h2 {
    text-align: center;
    color: #0056b3;
    margin-bottom: 20px;
    font-size: 24px;
    letter-spacing: 1px;
}

/* Text area styling */
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
    resize: none; /* Prevent resizing */
}

/* Button styling */
button {
    background-color: #007bff;
    color: white;
    padding: 12px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 25px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

/* Button hover state */
button:hover {
    background-color: #0056b3;
}

/* Result styling */
#result {
    margin-top: 30px;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    color: #28a745;
    transition: color 0.3s;
}
